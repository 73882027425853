@import "abstracts/_abstracts";
.QuickSearchItem {
	$block: &;
	border-bottom: 1px solid #ededed;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: .8rem 1rem;
	text-decoration: none;
	color: #000;
	font-weight: 700;
	font-size: .75em;
	transition: var(--animationBase);

	@include on-event {
		text-decoration: none;
		background: #0000000a;
	}

	&-image {
		margin-right: var(--spaceSm);
		width: 4em;

		&:empty {
			display: none;
		}
	}

	&-content {
		flex: 1;
		font-size: var(--textMd);
	}

	&-name {
		transition: var(--animationBase);

		#{$block}:hover & {
			color: var(--colorBrand);
		}
	}

	&-subText {
		font-size: .85em;
		font-weight: 400;
		color: var(--colorTextSubtle);
	}
}